import icons from "../icons/icons"
const $ = require("jquery") // if we need
// import Swup from "swup";
import { Fancybox } from "@fancyapps/ui"
import "@fancyapps/ui/dist/fancybox.css"
import Swiper, { Navigation, Pagination, Autoplay } from "swiper"
import "swiper/swiper-bundle.css"
import ModalVideo from "modal-video"
import "modal-video/css/modal-video.css"

Swiper.use([Navigation, Pagination, Autoplay])

Fancybox.bind("[data-fancybox]", {
  // Your options go here
})

new ModalVideo(".js-modal-btn")

var app = {
  iconSpriteFn() {
    icons.forEach(iconSpriteFn)
    function iconSpriteFn(item, index) {
      const iconSprite = document.querySelector("#iconSprite")
      if (iconSprite) {
        iconSprite.innerHTML +=
          "<div class='icon-sprite__item'>" +
          "<span class='icon-sprite__number'>" +
          (index + 1) +
          "</span>" +
          "<div class='icon-sprite__preview'>" +
          item.iconSvg +
          "</div>" +
          "<div class='icon-sprite__name'>" +
          item.iconId +
          "</div>" +
          "</div>"
      }

      const icon = document.querySelectorAll(".icon")
      if (icon) {
        Array.prototype.forEach.call(icon, (el) => {
          let dataIconId = el.getAttribute("data-icon-id")
          if (dataIconId == item.iconId) {
            el.innerHTML = item.iconSvg
          }
        })
      }
    }
  },
  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },
  headerScrollSpyFn() {
    $(".header__menu__item").click(function (e) {
      $(".header__menu__item").removeClass("active")
      $(this).addClass("active")
      var linkHref = $(this).attr("href")
      var idElement = linkHref.substr(linkHref.indexOf("#"))
      $("html, body").animate(
        {
          scrollTop: $(idElement).offset().top - 170,
        },
        800
      )
      return false
    })
  },
  stickyHeaderFn() {
    $(window).scroll(function () {
      var sticky = $(".header"),
        scroll = $(window).scrollTop()

      if (scroll >= 100) sticky.addClass("header--sticky")
      else sticky.removeClass("header--sticky")
    })
  },

  heroSliderFn() {
    setTimeout(() => {
      const swiper = new Swiper(".js-hero-slider", {
        loop: true,
        slidesPerView: "auto",
        autoplay: {
          delay: 2000,
        },
        navigation: {
          nextEl: ".hero__slider .swiper-button-next",
          prevEl: ".hero__slider .swiper-button-prev",
        },
        breakpoints: {
          320: {
            spaceBetween: 10,
          },
          769: {
            spaceBetween: 20,
          },
          993: {
            spaceBetween: 40,
          },
        },
      })
    }, 1035)
  },
  gallerySliderFn() {
    const swiper = new Swiper(".js-gallery-slider", {
      loop: false,
      slidesPerView: 1,
      spaceBetween: 0,
      observer: true,
      observeParents: true,
      pagination: {
        el: ".js-gallery-slider .swiper-pagination",
        type: "bullets",
      },
    })
  },
  mainTab: function () {
    $("body").on("click", ".js-tab-item", function (e) {
      $(".js-tab-item").removeClass("active")
      $(".js-tab-item.active").removeClass("active")
      $('.js-tab-item[data-filter="' + $(this).data("filter") + '"]').addClass(
        "active"
      )
      var dataFilter = $(this).data("filter")
      $(".filter-tab-item").delay(250).addClass("filtering")
      // $('.js-filter-tab-item.' + dataFilter).removeClass('filtering');
      window.setTimeout(function () {
        $(".filter-tab-item").removeClass("filtering")
        $(".filter-tab-item").addClass("filtered")
        $(".filter-tab-item." + dataFilter).removeClass("filtered")
      }, 250)
      e.preventDefault()
      return false
    })
  },
  inputFocusandTexted() {
    $(".input-item input, .input-item textarea").focus(function () {
      $(this).parent(".input-item").addClass("input-item--focused")
    })
    $(".input-item input, .input-item textarea").focusout(function () {
      if ($(this).val() === "") {
        $(this).parent(".input-item").removeClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      } else {
        $(this).parent(".input-item").addClass("input-item--valued")
        $(this).parent(".input-item").removeClass("input-item--focused")
      }
    })
  },
  headerMenuFn() {
    $(".header__mobile-button").on("click", () => {
      $(".header__mobile-button #hamburger").toggleClass("open")
      $(".header__right").toggleClass("active")
    })
  },
  callusFn() {
    $(".call-us__button").click(function () {
      $(this).next().toggleClass("active")
    })
  },

  init: function () {
    app.iconSpriteFn()
    app.load()
    app.headerScrollSpyFn()
    app.stickyHeaderFn()
    app.heroSliderFn()
    app.gallerySliderFn()
    app.mainTab()
    app.inputFocusandTexted()
    app.headerMenuFn()
    app.callusFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
